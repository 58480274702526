.condition_description span.ant-input-textarea-suffix {
  position: absolute !important;
  top: 3px;
  right: unset !important;
  bottom: unset;
  display: inline-flex;
  align-items: center;
  margin-left: -20px !important;
}

.ant-tag-checkable:active {
  background-color: transparent !important;
}

.tags-container {
  margin-bottom: 0.5rem;
  
  .checkable-tag {
      padding: 2px;
      margin-bottom: 2px;
    }
}

.tag-and-description {
  grid-column: span 2;
  margin-bottom: 0.5rem;
}
