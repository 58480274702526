@import "~styles/variables.scss";

.asset-location-links {
  margin-bottom: 1rem;
  a {
    color: $secondary;
    text-decoration: underline;
    margin-right: 0.3rem;
    font-size: 13px;
    &:hover {
      color: $primary;
    }
  }
}

.table-name-link {
  margin-bottom: 1rem;
  color: $secondary;
  text-decoration: underline;
  margin-right: 0.3rem;
  &:hover {
    color: red;
  }
}
