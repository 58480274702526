
.grid-skeleton  {
  width: 100%!important;
}

.grid-list-card > .ant-card-actions {
    margin-top: auto!important; 
}

.download-facility-switch-grid-view{ 
  margin: 5px 0 5px 0;
}