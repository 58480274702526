@import "~styles/variables.scss";

.searchable-tree-container {
  .label {
    color: $secondary;
    font-size: 14px;
  }


  .dropdown {

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    cursor: pointer;
    transition: all 300ms;
    position: relative;
    font-size: 13px;
    z-index: 10;
    &.form-variant {
      margin-bottom: 2rem;
      border: 1px solid $additional1-10-rgb;
      background-color: $background;
      height: 2rem;
      border-radius: 4px;
      padding: 0.5rem 0.5rem;


      .value {
        white-space: nowrap;
        overflow-x: hidden;
      }
  
      &.selected,
      &:hover {
        border-color: $secondary;
      }
    }
    &.filter-variant{
      flex-grow: 1;
      min-width: 200px;
      border-bottom: 1px solid $additional1-10-rgb;
      background-color: $background;
      min-height: 2rem;
      padding: 0.25rem 0.5rem;

      .ant-typography {
        display: flex;
        flex-wrap: wrap;
      }

      .ant-tag {
        margin: 0.125rem 0.5rem 0.125rem 0;
        padding: 0 0.5rem;
        height: 1.375rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .anticon-close {
          font-size: 11px;
          margin-left: 5px;
        }
      }
    }

  }

  .searchable-tree {
    position: absolute;
    top: 2.2rem;
    left: 0;
    // z-index: 1;
    width: 100%;
    background-color: $background;
    box-shadow: 0 10px 35px -5px rgb(0 0 0 / 15%);
    padding: 0.3rem;
    border: 1px solid $additional1-10-rgb;
    border-radius: 4px;

    .search-input {
      border: 1px solid $additional1-10-rgb;
      height: 2rem;
      border-radius: 4px;
      padding: 0.5rem 0.3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      cursor: pointer;
      transition: all 300ms;

      &:hover {
        border-color: $secondary;
      }
    }
    .ant-tree {
      max-height: 300px;
      overflow-y: auto;
      z-index: 99;

      .ant-tree-switcher.ant-tree-switcher_close svg {
        transform: rotate(0deg);
      }
      .ant-tree-switcher.ant-tree-switcher_open svg {
        transform: rotate(90deg);
      }


      .ant-tree-node-content-wrapper {
        width:100%
      }

    }
  }
}
