// breakpoint mixins:
@mixin small-mobile-breakpoint() {
  @media (max-width: $sm-max-width) {
    @content;
  }
}

@mixin mobile-breakpoint() {
  @media (max-width: $md-max-width) {
    @content;
  }
}

@mixin mobile-and-medium-breakpoint() {
  @media (max-width: $xxl-max-width) {
    @content;
  }
}

@mixin scaled-styles-breakpoint() {
  @media (min-width: $md-min-width) and (max-width: $xxl-max-width) {
    @content;
  }
}

// font mixins:
@mixin font-xxxl($medium-screen-scaling: true) {
  font-size: $fs-xxxl;
  line-height: $lh-md;
  @if $medium-screen-scaling {
    @include scaled-styles-breakpoint {
      font-size: scale-medium-breakpoint-value($fs-xxxl);
    }
  }
}

@mixin font-xxl($medium-screen-scaling: true) {
  font-size: $fs-xxl;
  line-height: $lh-md;
  @if $medium-screen-scaling {
    @include scaled-styles-breakpoint {
      font-size: scale-medium-breakpoint-value($fs-xxl);
    }
  }
}

@mixin font-xl($medium-screen-scaling: true) {
  font-size: $fs-xl;
  line-height: $lh-md;
  @if $medium-screen-scaling {
    @include scaled-styles-breakpoint {
      font-size: scale-medium-breakpoint-value($fs-xl);
    }
  }
}

@mixin font-lg($medium-screen-scaling: true) {
  font-size: $fs-lg;
  line-height: $lh-md;
  @if $medium-screen-scaling {
    @include scaled-styles-breakpoint {
      font-size: scale-medium-breakpoint-value($fs-lg);
    }
  }
}

@mixin font-md($medium-screen-scaling: true) {
  font-size: $fs-md;
  line-height: $lh-md;

  @if $medium-screen-scaling {
    @include scaled-styles-breakpoint {
      font-size: scale-medium-breakpoint-value($fs-md);
    }
  }
}

@mixin font-sm($medium-screen-scaling: true) {
  font-size: $fs-sm;
  line-height: $lh-md;
  @if $medium-screen-scaling {
    @include scaled-styles-breakpoint {
      font-size: scale-medium-breakpoint-value($fs-sm);
    }
  }
}

@mixin font-xs($medium-screen-scaling: true) {
  font-size: $fs-xs;
  line-height: $lh-md;
  @if $medium-screen-scaling {
    @include scaled-styles-breakpoint {
      font-size: scale-medium-breakpoint-value($fs-xs);
    }
  }
}

@mixin font-xxs($medium-screen-scaling: true) {
  font-size: $fs-xxs;
  line-height: $lh-md;
  @if $medium-screen-scaling {
    @include scaled-styles-breakpoint {
      font-size: scale-medium-breakpoint-value($fs-xxs);
    }
  }
}

@mixin font-xxxs($medium-screen-scaling: true) {
  font-size: $fs-xxxs;
  line-height: $lh-md;
  @if $medium-screen-scaling {
    @include scaled-styles-breakpoint {
      font-size: scale-medium-breakpoint-value($fs-xxxs);
    }
  }
}

$base__font-size: 13 !default;

// Convert value rem() sass mixin
@function rem($px, $base: $base__font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
  // comment code above and uncomment below for change REMs to PXs
  //@return #{$px}px;
}

// Transitions
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}
@mixin transition-very-slow() {
  transition: all 0.4s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}

@mixin pdf-print() {
  :global(.pdf-print) & {
    @content;
  }
}

// apply styles that will cover the ant-design defaults if needed (by adding styles in context of each div in the body tag)
@mixin ant-style() {
  body > div {
    @content;
  }
}

// flex settings sets
@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin card-animation($height) {
  @keyframes show-details {
    from {
      height: 0;
      opacity: 0;
    }
    to {
      height: $height;
      opacity: 1;
    }
  }

  .showDetailsContainer {
    height: $height;
    width: 100%;
    overflow-y: hidden;
    animation-name: show-details;
    animation-duration: 0.2s;
  }

  @keyframes hide-details {
    from {
      height: $height;
      opacity: 1;
    }
    to {
      height: 0;
      opacity: 0;
    }
  }

  .hideDetailsContainer {
    height: 0;
    width: 100%;
    animation-name: hide-details;
    animation-duration: 0.2s;
    overflow: hidden;
  }
}

@mixin modifier-inputs-common {
  .multipleInputElement {
    margin-bottom: 8px;
    padding: 4px 0 8px 0;

    :global(.ant-select-selection--single .ant-select-selection__rendered) {
      margin-right: 0;
    }

    :global(.ant-collapse-header) {
      font-size: $fs-xxs !important;
      color: $secondary !important;
      padding: 0 0 0 22px !important;
    }

    :global(.anticon) {
      left: 0 !important;
    }

    :global(.ant-collapse-content) {
      width: 100%;
    }

    :global(.ant-collapse-content-box) {
      padding: 16px 0 16px 22px !important;
    }

    button {
      padding-right: 0;

      &:global(.ant-btn-link) {
        color: $primary;
      }

      span {
        position: relative;
        bottom: 1px;
      }
    }
  }

  .multipleInputLabel {
    display: flex;
    color: $secondary;
    justify-content: space-between;
    align-items: center;
    font-size: $fs-xxs;

    button {
      padding-right: 0;

      &:global(.ant-btn-link) {
        color: $primary;
      }
    }
  }

  .multipleInputLabel > button,
  .multipleInputElement > button,
  .extraButton {
    margin-top: -4px;
    padding: 0;
    font-size: $fs-xxs;

    :global(.anticon) {
      width: 22px;
      height: 22px;
      font-size: 18px;
    }
  }

  .multipleInputElement > button {
    margin-left: -4px;
  }
}

@mixin custom-scrollbar {
  & > {
    ::-webkit-scrollbar,
    ::-webkit-scrollbar-thumb {
      width: $scrollbar-size;
      height: $scrollbar-size;
      background-clip: padding-box;
      border: 2px solid transparent;
      border-radius: $scrollbar-size;
    }

    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 0 $scrollbar-size;
    }

    ::-webkit-scrollbar-track {
      border-radius: 0;
      background: transparent;
      color: transparent;
    }
  }

  color: transparent;
  transition: color 0.2s;

  &:hover {
    color: $border-lighten;

    ::-webkit-scrollbar-thumb {
      &:hover {
        box-shadow: inset 0 0 0 $scrollbar-size $border-default;
      }
    }
  }
}

@mixin form-columns {
  @media (min-width: ($xs-min-width + 55px)) {
    display: grid;
    grid-template-columns: $form-column-width $form-column-width;
    grid-column-gap: 27px;
    grid-row-gap: 0;
  }
}

@mixin form-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include form-columns;
}
