@import "~styles/variables.scss";

.dataViewSwitch {
  display: inline-flex;
  justify-content: flex-end;
  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }
}

.option {
  margin: 2px 10px;
  color: $additional1;
  transition: color 0.2s;
  width: 24px;
  height: 24px;
}

.selected {
  color: $primary !important;
}
