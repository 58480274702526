.home-page {
  height: 100%;
}

.home-page .ant-alert {
  margin: 16px 0;
}

.home-page .switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}