@import "~styles/variables.scss";

.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 12px;

  .breadcrumb-item {
    padding: 0 0.4rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    .breadcrumb-label {
      color: $additional1-40;
      transition: color 300ms;
    }

    &:hover {
      .breadcrumb-label {
        color: $text-lighten;
      }
    }

    &.home-item {
      .home-icon {
        width: 24px;
        height: 24px;
        color: $text-lighten;
        margin-right: 1.1rem;
        transition: opacity 300ms;
        position: relative;
        top: -2px;
        opacity: 0;
      }

      &:hover {
        .home-icon {
          opacity: 1;
        }
      }
    }

    &.last-item {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $additional1-10;
      border-radius: 28px;
      padding: 0 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: $button-height;
      min-height: $button-height;
      pointer-events: none;
      cursor: pointer;

      @media (max-width: $xl-min-width) {
        margin-left: 16px;
      }

      @include scaled-styles-breakpoint {
        height: scale-medium-breakpoint-value($button-height);
        min-height: scale-medium-breakpoint-value($button-height);
        padding: 0 scale-medium-breakpoint-value(20px);
      }

      .breadcrumb-label {
        color: $text-lighten;
      }
    }
  }

  .breadcrumb-separator {
    color: $additional1-40;
    margin-left: 13px;
    margin-right: 13px;
  }
}
