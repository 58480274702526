.fabricContainer {
  & > div {
    margin-top: 12px;

    @media only screen and (max-width: 1199px) {
      margin-top: 30px;
    }
  }

  &:not(:first-child) {
    margin-top: 30px;
  }

  :global(.ant-table-empty .ant-table-placeholder) {
    border-bottom: none !important;
  }

  :global(.ant-collapse
      .ant-collapse-item
      .ant-collapse-header
      .ant-collapse-arrow) {
    left: unset;
    top: calc(50% - 2px);
    border-left: 1px solid #d9d9d9;
    padding-left: 16px;
    color: #444f56;
  }

  :global(.ant-collapse .ant-collapse-item .ant-collapse-header) {
    background: rgba(#444f56, 0.05);
    padding-right: 60px;
  }

  :global(.ant-collapse
      .ant-collapse-item
      .ant-collapse-header
      .collapse-header-container) {
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  :global(.ant-table .ant-table-tbody > tr) {
    background: white !important;

    &:hover {
      background: white !important;
    }

    &:hover td {
      font-size: 14px;
    }
  }

  :global(.ant-table .ant-table-tbody > tr:not(.hoverableRow)) {
    &:hover td {
      background: white !important;
      color: unset;
    }
  }

  :global(.ant-table .ant-table-tbody > tr td) {
    height: 32px !important;
    padding: 6px 10px;
    font-size: 14px;
  }

  :global(.grayed td) {
    color: #abbac7 !important;
  }

  :global(td:first-child) {
    width: 100%;
    min-width: 150px;
  }

  :global(td:nth-child(4)) {
    @media only screen and (max-width: 1599px) {
      display: none;
    }
    @media only screen and (max-width: 1199px) {
      display: block;
    }
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  :global(.noIdentation td:first-child) {
    padding-left: 8px !important;
  }

  :global(.identation td:first-child) {
    padding-left: 23px !important;
  }

  :global(.ant-collapse-content-box) {
    padding: 8px 8px 16px;
  }
}

.bypassWarningMessage {
  margin: 8px 7px 14px;
}

.description {
  line-height: 20px;
}

.validation-status-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #748595 !important;
  margin-right: 7px !important;
}
