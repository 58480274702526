.input-decreaser,
.input-increaser {
  width: 40px;
}

.input-input {
  .ant-input-number-handler-wrap {
    display: none !important;
  }

  .ant-input-number-input-wrap {
    input {
      text-align: center;
    }
  }

  .quantity-input {
    box-sizing: border-box;
    width: 60px !important;
    text-align: center;
    border-color: #e8e8e8;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.85);
    
    
    &:focus,
    &:active {
      box-shadow: none !important;
    }
  }
}

