@import "./variables.scss";

@mixin rowColors {
    &:nth-child(odd) {
      > td {
        background-color: $background;
      }
    }
  
    &:nth-child(even) {
      > td {
        background-color: $additional1-02-rgb;
      }
    }
  }

  .ant-table-tbody > tr {
    @include rowColors;
  }
  
  .ant-table-thead > tr {
    background-color: $background;
  }
  .ant-table-thead > tr > th, .ant-table-thead th.ant-table-column-sort {
    background-color: $background;
  }
  

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: $background;    
  }

  .ant-table-tbody > tr.ant-table-row:hover , .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    @include rowColors;   
}
  