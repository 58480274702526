@import "~styles/variables.scss";

.button-pair {
  display: inline-flex;
  align-items: center;

  button:first-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    &.ant-btn-sm {
      padding-left: 11px;
      padding-right: 9px;
    }
  }
  button:last-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    width: 39px;
  }
}
