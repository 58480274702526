@import '~styles/variables.scss';

.validation-message {
  display: flex;
  margin-bottom: 24px !important;
  color: #232323;

  svg {
    color: #444f56;
  }

  .validation-list {
    margin-top: 12px;
    padding-left: 14px;
  }
}

.notes-field {
  margin-top: 24px;
}

.asset-detail-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 24px;

  li {
    margin-bottom: 4px;
  }
}
