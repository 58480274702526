@import "~styles/variables.scss";

.ant-alert {
  background-color: white !important;
  border: solid 1px $additional1!important;
  border-radius: 5px;
}

.ant-input-group.ant-input-group-compact > * {
  height: 32px;
  width: 32px;
}

.ant-tooltip {
  .ant-tooltip-inner {
    border: solid 1px $additional1;
    color: $additional1;
    font-weight: normal;
    color: $additional1;
    background-color: $background;
    border-radius: 5px;
  }

  .ant-tooltip-arrow {
    overflow: hidden;
  }
  .ant-tooltip-arrow-content {
    background-color: $background;
  }
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  .ant-tooltip-arrow-content {
    border-bottom: 1px solid $additional1;
    border-right: 1px solid $additional1;
  }
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  .ant-tooltip-arrow-content {
    border-top: 1px solid $additional1;
    border-left: 1px solid $additional1;
  }
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  .ant-tooltip-arrow-content {
    border-top: 1px solid $additional1;
    border-right: 1px solid $additional1;
  }
}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  .ant-tooltip-arrow-content {
    border-bottom: 1px solid $additional1;
    border-left: 1px solid $additional1;
  }
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background-color: $background;

  &:hover {
    background-color: $additional1-05-rgb;
  }
}
  
 
$radioIconSize: 21px;
.table-header {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;

  .results {
    color: $additional1-50;
  }

  .listing-header-icon {
    width: 30px;
    height: 30px;
    margin-right: 1rem;
    margin-left: 0.5rem;
    text-align: center;

    .icon {
      width: 30px;
    }
  }
}

.ant-card {
  border: 0;

  &.ant-card-bordered {
    border: 1px solid $additional3;
    border-radius: 16px;
  }
  .ant-card-actions {
    background: transparent;
  }

  &:not(.ant-card-bordered) {
    .ant-card-head {
      padding: 0 0;
    }
    .ant-card-body {
      padding: 24px 0;
    }
  }

  &.noPadding {
    :global {
      .ant-card-head,
      .ant-card-body {
        padding: 0;
      }
    }
  }
}

button.ant-btn {
  border-radius: $border-radius;
  height: 31px;
  border: unset;
  font-weight: 400;
  text-shadow: unset !important;
  font-size: 13px;
  background-color: $additional1-10-rgb;
  color: $text-dark;

  &.ant-btn-sm {
    border-radius: $border-radius;
    height: 26px;
    font-size: 12px;
  }

  &.ant-btn-primary {
    background-color: $primary;
    color: $text-contrast;
  }

  &.ant-btn-clear {
    background-color: transparent;
    color: $text-lighten;
    box-shadow: unset;

    &:disabled {
      background-color: transparent;
    }

    &:hover {
      background-color: transparent;
    }
  }

  &.ant-btn-transparent {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;

    &.ant-btn-sm {
      padding-left: 10px;
      padding-right: 8px;
    }

    &:hover {
      background-color: transparent;
      border-color: $additional3;
      color: $text-lighten;
    }
  }

  &:disabled {
    background-color: $additional1-05-rgb;
    color: $additional1-40-rgb;
  }

  span {
    text-shadow: unset !important;
  }

  &:hover, &:focus {
    background-color: $additional1-10-rgb;
    color: $text-dark;
  }
}

div.ant-select {
  .ant-select-selector {
    outline: unset !important;
    box-shadow: 0 2px 0 $additional1-02;
    border: unset !important;
    border-bottom: 1px solid #e9e9e9;

    .ant-select-selection-placeholder {
      font-size: 12px;
      // color: $text-lighten;
      color: $additional1-40-rgb;
    }
  }
  
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: 0 2px 0 $additional1-02;
    border: unset;
    border-bottom: 1px solid #e9e9e9;
  }
}

h1.ant-typography {
  font-size: 26px;
  padding: 0;
  margin: 0;
}

.ant-switch-checked {
  background-color: $success
}

.ant-modal-mask {
  background-color: rgba(15, 12, 41, 0.5) !important;
}

.ant-modal-content {
  border-radius: 4px !important;
}

.ant-modal-confirm {
  .ant-modal-confirm-btns {
    display: flex;
    gap: 1rem;

    .ant-btn {
      border-radius: 28px !important;
      border-color: $primary;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary;
  border-color: $primary !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $primary;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $secondary !important;
}
.ant-checkbox-checked::after {
  display: none;
}

.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primary !important;
}

// .ant-pagination {
//   display: none;
// }

.ant-table-column-sorter {
  display: none;
}

.ant-pagination {
  .ant-pagination-item {
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    background-color: #e9e9e9;
    line-height: 32px;
    margin-right: 0;
    border-radius: 0;
    border: none;
  }
  .ant-pagination-item a:hover {
    color: black;
  }

  .ant-pagination-item-active {
    border: none;
    margin-right: 0px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }

  .ant-pagination-item-active a {
    color: white;
    background-color: red;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  // .ant-pagination-item-active a:hover {
    // color: black;
    // background-color: #e9e9e9;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    background-color: rgb(232, 232, 232);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
  .ant-pagination-jump-prev, .ant-pagination-jump-next {
    margin-right: 0px;
    margin-left: 0px;
  }

  .ant-pagination-prev {
    border-radius: 28px 0 0 28px;
    margin-right: 0px;
  }

  .ant-pagination-next {
    border-radius: 0 28px 28px 0;
    margin-left: 0px;
  }

  .ant-pagination-item-link {
    border: none;
    background-color: transparent;
    margin-right: 0px;
  }

  .ant-pagination-next:hover
    .ant-pagination-item-link {
    color: black;
  }
  
  .ant-pagination-prev:hover
  .ant-pagination-item-link{ 
    color: black;
  }

  .ant-pagination-jump-next:hover > a { 
    .ant-pagination-item-link-icon {
      color: black;
    }
    }
  

    .ant-pagination-jump-prev:hover > a { 
      .ant-pagination-item-link-icon {
        color: black;
      }
      }
    
  
}

.ant-table-pagination-right {
	justify-content: center;
}

.ant-table-thead {
  user-select: none;

  .ant-table-cell {
    cursor: auto !important;
    font-size: 16px;

    &:hover {
      background-color: unset !important;
    }
  }
}
