@import "~styles/variables.scss";

.drawer-photo-section {
  margin-bottom: 1rem;

  .form-element {
      display: flex;
      flex-direction: column;

    .ant-form-item-label{
      text-align: left;
    }
  }

  .ant-form-item-control-input{ 
    width: 530px;
  }

  .photo-input {
    display: none !important;
  }

  .photo-list-container {
    margin-top: 1rem;

    .carousel .control-dots {
      top: 0 !important;
      bottom: unset !important;
    }

    .image-item {
      padding-bottom: 1rem;
      position: relative;

      button {
        height: 2rem;
        padding: 0;
        border-radius: 0;
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        background-color: $secondary;
        color: $background;
      }

      img {
        max-width: 100%;
      }
    }
  }
}

.upload-button {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0.3rem;

  &:hover {
    color: $primary;
  }
}

.no-image-available {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  margin-top: 12px;
  background-color: #ffffff;
}

.rejectedImagesAlert {
  padding: 8px 15px;
  border-color: #ff1945 !important;
  color: #ff1945;
  font-size: 14px;

  svg {
    display: flex;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-top: 4px;
  }

  ul {
    margin-top: 8px;
    padding-left: 16px;
  }

  .ant-alert-message {
    color: #ff1945;
  }
}
