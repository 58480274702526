@import "~styles/variables.scss";

.sort-button {
  width: 40px;
  display: inline-flex;
  cursor: pointer;

  .icon {
    height: 22px;
    color: $secondary;
  }

  .arrow-up {
    position: relative;
    transform: rotate(-90deg) scaleX(1);
    transform-origin: 50% 50%;
    transition: all 200ms;
  }

  .arrow-down {
    position: relative;
    transform: rotate(90deg) scaleX(1);
    transform-origin: 50% 50%;
    margin-left: -20px;
    transition: all 200ms;
  }

  &.sort-down {
    .arrow-down {
      transform: rotate(90deg) scaleX(1.3);
      .icon {
        color: $primary;
      }
    }
  }
  &.sort-up {
    .arrow-up {
      transform: rotate(-90deg) scaleX(1.3);
      .icon {
        color: $primary;
      }
    }
  }

  &.sort-none {
    .icon {
      height: 22px;
      color: $additional1;
    }
  }
}
