@import "./mixins.scss";

@function color-disabled($color) {
  @return rgba($color, 0.4);
}

// Accent colors (named to match style guide)
$primary: #ff1945;
$secondary: #232323;
$additional1: #444f56;
$additional2: #f1f4f3;
$additional3: #d9d9d9;
$additional4: #ff7757;

$additional1-02: rgba($additional1, 0.02);
$additional1-05: rgba($additional1, 0.05);
$additional1-10: rgba($additional1, 0.1);
$additional1-20: rgba($additional1, 0.2);
$additional1-40: rgba($additional1, 0.4);
$additional1-50: rgba($additional1, 0.5);

// no alpha channel color, but looks like the same color with opacity put on white background:
$additional1-02-rgb: #fbfcfc;
$additional1-05-rgb: #f5f6f6;
$additional1-10-rgb: #e8e8e8;
$additional1-40-rgb: #b4b8bc;

$success: #21ad37;
$info: $additional1;
$warning: #fd7757;
$danger: $primary;

$disabled-light: $additional1-40;

// Main colors
$background: #fff;

$card: $additional2;
$card-accent: #62aee6;

$border-dark: #1a1818;
$border-darken: #707070;
$border-default: #cbcbcb;
$border-lighten: $additional3;

$text-dark: #000;
$text-lighten: $secondary;
$text-disabled: rgba(0, 0, 0, 0.25);
$text-contrast: #fff;

$icon-primary: #fd1845;
$icon-table: #2c3e50;

$button-primary-disabled: color-disabled($primary);
$button-secondary: $additional1-10;
$button-hover: $additional1-20;

$table-accent: $additional1-02;

$drawer-background: #fcfcfc;
$drawer-mask: rgba($background, 0.77);

// Shadow colors
$shadow-1: rgba(0, 0, 0, 0.1);
$shadow-2: rgba(0, 0, 0, 0.15);

// Chart colors
$chart-green: #21ad37;
$chart-green-lighten: #b9d837;
$chart-blue-lighten: #62aee6;
$chart-blue: #4b6ad1;
$chart-violet: #b431c6;
$chart-violet-lighten: #db3eb1;
$chart-orange: #ff6c4a;
$chart-orange-lighten: #fd9070;
$chart-yellow-lighten: #fac560;
$chart-yellow: #ffe834;

// Font Family
$font-family: "Roboto", sans-serif;
$base__font-family: $font-family !important;

// Font Sizes
$fs-xxxl: 64px;
$fs-xxl: 39px;
$fs-xl: 36px;
$fs-lg: 32px;
$fs-md: 26px;
$fs-sm: 20px;
$fs-xs: 16px;
$fs-xxs: 14px;
$fs-xxxs: 11px;

// Line Heights
$lh-xs: 1;
$lh-sm: 1.1;
$lh-md: 1.2;
$lh-lg: 1.3;
$lh-xl: 1.5;

$medium-screen-scalar: 0.8;
@function scale-medium-breakpoint-value($value) {
  @return ceil($value * $medium-screen-scalar);
}

$nav-height: 136px;
$nav-height-medium: scale-medium-breakpoint-value($nav-height);
$context-panel-closed-width: 130px;

// z-indexes:
$nav-top: 9998;
$nav-scrolled: 10001;
$mobile-context-panel: 10002;
$ant-modal-mask: 10003;
$ant-modal-wrap: 10004;
$always-on-top: 999999;

// custom scrollbar:
$scrollbar-size: 12px;

// inputs:
$input-min-width: 50px;
$input-height: 34px;
$border-color: $additional3;
$hover-border-color: $additional1;
$focus-shadow: 0 0 0 2px rgba($additional1, 0.1);
$form-column-width: 252px;

// button
$border-radius: 28px;
$button-height: 38px;
$button-small-height: 32px;

// media query breakpoints
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$md-min-width: 992px;
$md-max-width: 991px;
$sm-min-width: 768px;
$sm-max-width: 767px;
$xs-min-width: 576px;
$xs-max-width: 575px;
