@import "~styles/variables.scss";

.fabric-elements-group {
  .validation-status-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
  }

  .validation-issues {
    margin-bottom: 0.5rem;
    padding-bottom: 0.3rem;
    padding-top: 0.8rem;

    div.ant-typography {
      margin-bottom: 0.2rem;

      div.ant-typography {
        margin-bottom: -0.3rem;
      }
    }
  }

  .section-container {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .section-header {
      display: flex;
      width: 100%;
      gap: 0.5rem;
      align-items: center;

      .percentage.not-specified {
        color: $primary;
      }

      .section-name {
        width: 100%;
      }

      .ant-typography {
        opacity: 0.4;
      }
    }

    .selector-id {
      width: 85px;
    }
    .validation-status-icon {
      flex-shrink: 0;
    }

    .fabric-asset-container {
      display: flex;
      width: 100%;
      gap: 0.5rem;
      align-items: center;

      .fabric-asset-name {
        margin-left: 1.5rem;
        width: 100%;
      }
    }
  }
}
