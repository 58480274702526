@import '~styles/variables.scss';

.applicationSwitcherFixedDropdown {
  position: fixed;
  z-index: $always-on-top !important;
  background-color: $background;
}

.applicationSwitcherDropdown {
  position: relative;
  cursor: pointer;
}

.applicationSwitcherIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 6px;
  height: 26px;
  width: 26px;
}

.applicationSwitcherMenuTitle {
  font-size: 20px;
  margin: 12px;
  color: $secondary;
}

.applicationSwitcherListItemsMenu {
  width: 200px;
}

.applicationSwitcherListItem {
  display: flex;
}

.applicationSwitcherListItemToltip {
  z-index: $always-on-top;
}

.applicationSwitcherListItemWrapper {
  display: flex;
  flex-direction: column;
  outline: none;

  .label {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .select {
    width: 100%;
  }
}

.disabledApplicationSwitcherListItem {
  cursor: not-allowed;
  color: $additional1-40-rgb;
}

.disabledApplicationSwitcherListItemIcon {
    stroke: $additional1-40-rgb !important;
}

.applicationSwitcherListItemLabel {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.applicationSwitcherListItemIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 26px;
  width: 26px;
  margin-right: 16px;  
  stroke: $primary;
}
