@import "~styles/variables.scss";

.form-alert-box {
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, .1);

  &.ant-alert {
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    margin: 8px 0 20px;
    border: 2px solid #ff7757 !important;
  }
  
  .icon {
    display: flex;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    margin-top: 4px;  
    color: #ff7757;
  }

  .ant-alert-close-icon {
    display: flex;
    margin-top: 7px;

    svg {
      width: 20px;
      height: 20px;
      color: #ff7757;
    }
  }

  .ant-alert-content {
    margin-left: 12px;
    margin-right: 12px;
  }

  h2 {
    color: #ff7757;
  }

  p {
    font-size: 16px;
    color: #ff7757;
  }
}