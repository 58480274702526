mark {
  background: skyblue !important;
  color: white;
}

.search-results {
  max-height: 50vh;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
}

.result:hover {
  color: #ea4c56;
}

.searchMenu {
  width: 100%;
  border: 1px solid black!important;
  border-radius: 5px 0px 0px 5px!important;
  max-width: 550px;
  flex-grow:1;

  .ant-input-suffix {
    :first-child {
      opacity: 0;
      position: absolute;
    }
    &:before {
      content: "";
      height: 24px;
      width: 24px;
      z-index: 10;
      background-image: url("../../../assets/icons/search.svg");
      display: inline-block;
      margin-top: -2px;
      background-size: 24px 24px;
      background-repeat: no-repeat;
    }
  }
}
  
.moreButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  height: 50px;
  left: 0;

  .btnLoadMore {
    color: white;
    border-color: #ea4c56;
    background-color: #ea4c56;
  }
}
