@import "~styles/variables.scss";
$sidebarTransitionDuration: 250ms;

.app-sidebar {
  height: 100%;
  width: fit-content;
  max-width: 33%;
  flex-shrink: 0;
  padding-left: 18px;
  padding-right: 10px;
  transition: all $sidebarTransitionDuration;
  top: 0;
  position: sticky;

  .fold-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    flex-shrink: 0;
    margin-left: 1rem;
    transform: rotate(180deg);
    transform-origin: 50% 50%;
    transition: all $sidebarTransitionDuration;
    cursor: pointer;

    .icon {
      color: $text-lighten;
    }
  }

  .sidebar-item {
    padding-left: 12px;
    padding-top: 14px;
    padding-bottom: 14px;
    transition: all $sidebarTransitionDuration;
    width: 100%;

    .item-header {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &:not(:last-child):not(:nth-child(1)) {
      border-bottom: 1px solid $additional1-10;
    }

    .sidebar-icon {
      width: 30px;
      height: 30px;
      margin-right: 1rem;
      text-align: center;

      .icon {
        width: 30px;
        color: $additional1-40;
      }
    }

    .item-text {
      font-size: 16px;

      .item-superscript {
        font-size: 9.75px;
        vertical-align: top;
        margin-left: 3px;
      }
    }

    .small-item-text {
      font-size: 13px;
      margin-left: -7px;
    }

    &.item-assets {
      transition: all $sidebarTransitionDuration;
      height: 59px;
    }

    &.sidebar-header {
      display: flex;

      .sidebar-icon {
        margin-left: 2px;
      }

      .ant-select,
      .button-pair {
        transition: width $sidebarTransitionDuration;
      }

      .ant-select {
        width: 100%;
        margin-right: 20px;
      }

      .sidebar-icon {
        height: 24px;
      }

      .sidebar-icon .icon {
        width: 24px;
        height: 24px;
        color: $icon-primary;
      }
    }

    &.item-my-work {
      .sidebar-icon .icon {
        color: $icon-primary;
      }
    }

    &.item-validation {
      .sidebar-icon .icon {
        width: 24px;
        height: 24px;
        color: $icon-primary;
      }
    }

    .sub-item {
      display: flex;
      align-items: center;
      margin-left: 37px;
      margin-top: 10px;
      gap: 4px;

      .sub-item-icon {
        width: 16px;
        height: 17px;
      }

      .sub-item-text {
        font-size: 13px;
      }
    }
  }

  &.app-sidebar-collapsed {
    width: 0px;
    display: none;

    .fold-icon {
      transform: rotate(0);
      margin-left: -7px;
    }

    .sidebar-item.sidebar-header {
      opacity: 1;
      transition: unset;
      transform: unset;
      // transform: translateX(-2px);

      .ant-select,
      .button-pair {
        opacity: 0;
        width: 0;
      }
    }

    .item-assets {
      transform: translateX(-60px);
      opacity: 0;
      height: 0;
      padding: 0;
      margin: 0;
    }

    .sidebar-item {
      opacity: 0;
      transform: translateX(-20px);
    }
  }

  .knowledge-centre-link {
    color: $secondary;
    text-decoration: underline;

    &:hover {
      color: red;
    }
  }

  .helpdeskNumber {
    text-decoration: none;
    font-size: 13px;
    color: $secondary
  }
}

.app-sidebar-drawer {
  .ant-drawer-content-wrapper {
    max-width: fit-content !important;
  }

  .ant-drawer-body {
    overflow-x: hidden;
  }

  .app-sidebar {
    padding-left: 0px;
  }
}