@import "~styles/variables.scss";

.filter-header {
  display: flex;
  justify-content: space-between;
  margin-top: 2.75rem;
  margin-bottom: 1rem;

  .filters {
    display: flex;
    flex-grow: 1;
    gap: 1rem;
    width: 58.33333333%;
    align-items: flex-end;

    .status-filter {
      width: calc(100% - 38px - 200px - 1rem);

      .ant-select-arrow {
        right: 3px;
        transform: translate(-50%, -50%);
        top: 50%;
      }
    }

    .sort-by-filter {
      width: 200px;
      flex-shrink: 0;
    }
  }

  .dropdown-button {
    outline: unset !important;
    box-shadow: 0 2px 0 $additional1-02;
    border: unset !important;
    border-bottom: 1px solid #e9e9e9 !important;
    background-color: unset;
    border-radius: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: unset;
    }
  }
}
