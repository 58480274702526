@import "~styles/variables.scss";
$sidebarTransitionDuration: 250ms;

.page-header {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 44px;
  
  .fold-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    flex-shrink: 0;
    transform: rotate(180deg);
    transform-origin: 50% 50%;
    transition: all $sidebarTransitionDuration;
    cursor: pointer;
    
    .icon {
      color: $text-lighten;
    }
  }
  
  &.fold-icon-collapsed {
    .fold-icon {
      transform: rotate(0);
    }
  }
}