@import "~styles/variables.scss";

.spinner {

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  &__spinner {
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
  }

  &__message {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
}